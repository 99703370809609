import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import CarAdded from "../car/car-added"
import {Link, useIntl} from "gatsby-plugin-intl";

class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const CarFound = () => {
    const intl = useIntl();

  const pageContent = () => {
    return(
      <div className="col-lg-8">
        <CarAdded navigateTo='/mycars/update-car'/>
      </div>
    )
  };

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.mycars.car-found" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="my-cars" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>
    </Layout>
  )
};

export default CarFound
